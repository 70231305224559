#panelPaneles {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

#panelPaneles #app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#panelPaneles #popUpPizarraPaneles {
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
}

#panelPaneles .contenedorMenu {
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 1rem;
  display: flex;
}

#panelPaneles .miniatura {
  aspect-ratio: 16 / 9;
  box-sizing: border-box;
  width: 25%;
  padding: 2rem;
}

#panelPaneles .miniatura div.contenedorTitulo {
  background-color: #6f559f;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: .5rem;
  display: flex;
}

#panelPaneles .miniatura div.contenedorTitulo span {
  color: #fff;
  text-align: center;
  font-size: 1rem;
}

#panelPaneles .miniatura img {
  border: 1px solid #6f559f;
  width: 100%;
}

#panelPaneles .fondoModales {
  z-index: 3;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#panelPaneles #closeWidget {
  position: absolute;
  top: -15px;
  right: -15px;
}

#panelPaneles img#closeWidget {
  width: 33px;
  height: 33px;
}

#panelPaneles .seleccionablePaneles {
  aspect-ratio: 16 / 9;
  background-color: #fff;
  width: 75%;
  margin-bottom: 3rem;
  position: relative;
}

#panelPaneles .contenedorPizarraYBotonera {
  width: 100%;
  height: 100%;
  position: relative;
}

#panelPaneles .contenedorPizarra {
  width: 100%;
  height: 100%;
  position: absolute;
}

#panelPaneles .contenedorPizarra .papeleraPizarra {
  width: 2rem;
  position: absolute;
  bottom: 1rem;
  right: .5rem;
}

#panelPaneles div.pizarra {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#panelPaneles canvas {
  touch-action: none;
}

#panelPaneles div.botoneraPizarra {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  display: flex;
  position: absolute;
  bottom: -75px;
}

#panelPaneles div.boton {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin-left: 1rem;
}

#panelPaneles div.boton.seleccionado {
  transform: scale(1.2);
}

#panelPaneles div.boton.goma {
  background-color: gray;
  background-image: url("goma.c018b15e.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  margin-left: 0;
}
/*# sourceMappingURL=index.cf582620.css.map */

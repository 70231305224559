#panelPaneles {
	width: 100vw;
	height: 100vh;
	margin: 0;

	#app {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	#popUpPizarraPaneles {
		background-size: contain;
		background-repeat: no-repeat;
		height: 100%;
	}

	/* Menu en miniatura */
	.contenedorMenu {
		padding: 1rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.miniatura {
		width: 25%;
		aspect-ratio: 16 / 9;
		padding: 2rem;
		box-sizing: border-box;

		div.contenedorTitulo {
			background-color: #6f559f;
			padding: .5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 1rem;

			span {
				color: white;
				font-size: 1rem;
				text-align: center;
			}
		}
	}

	.miniatura img {
		width: 100%;
		border: 1px solid #6F559F;
	}

	/* Modal */
	.fondoModales {
		width: 100vw;
		height: 100vh;
		background-color: rgb(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
	}

	#closeWidget {
		position: absolute;
		top: -15px;
		right: -15px;
		/* z-index: 2; */
	}

	img#closeWidget {
		width: 33px;
		height: 33px;
	}

	.seleccionablePaneles {
		width: 75%;
		position: relative;
		aspect-ratio: 16 / 9;
		margin-bottom: 3rem;
		background-color: white;
	}

	/* Zona de la pizarra */
	.contenedorPizarraYBotonera {
		height: 100%;
		width: 100%;
		position: relative;
		
	}

	.contenedorPizarra {
		height: 100%;
		width: 100%;
		position: absolute;

		.papeleraPizarra {
			width: 2rem;
			position: absolute;
			right: .5rem;
			bottom: 1rem;
		}
	}

	div.pizarra {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	canvas {
		touch-action: none;
	}

	div.botoneraPizarra {
		width: 100%;
		height: 75px;
		display: flex;
		position: absolute;
		bottom: -75px;
		justify-content: center;
		align-items: center;
		background-color: rgba(255, 255, 255, 1);
	}

	div.boton {
		width: 50px;
		height: 50px;
		border-radius: 100%;
		margin-left: 1rem;
	}

	div.boton.seleccionado {
		transform: scale(1.2);
	}

	div.boton.goma {
		margin-left: 0;
		background-color: gray;
		background-image: url("../img/goma.png");
		background-size: 80%;
		background-repeat: no-repeat;
		background-position: center;
	}
}
